.input {
  display: flex;
  max-width: 100%;
  min-width: min-content;
  width: 100%;
  position: relative;

  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Grayscale-300, #EAEEF3);
  background: var(--Grayscale-0, #FFF);
  box-shadow: 0px 1.5px 4px 0px rgba(10, 9, 11, 0.04);
  outline: none;
  img {
    margin-left: 12px;
    margin-right: 12px;
  }

}
.errorHelp {
  display: none;
  position: absolute;
  color: red;
  font-size: 10px;
  bottom: -22px;
}
.errorHelpActive {
  display: block;
}
.error {
  outline: 1px solid red;
}
.disabled {
  background: var(--Grayscale-0, #ececec);
}

[contentEditable=true]:empty:not(:focus):before {
  content: attr(data-text);
  color: var(--Grayscale-500, #A1ABB9);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.editable {
  padding: 12px;
  background-color: transparent;
  outline: none;
  border: none;
  overflow-x: hidden;
  width: 100%;
  min-width: 150px;
}
.compact {
  padding: 6px 12px;
  min-width: auto;
}