
.textarea {
  display: flex;
  height: 140px;
  padding: 12px;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--Grayscale-300, #EAEEF3);
  background: var(--Grayscale-0, #FFF);

  /* Default */
  box-shadow: 0px 1.5px 4px 0px rgba(10, 9, 11, 0.04);

}

.textarea_withIco {
  background-image: url("./../Icons/ico-clock.svg");
  background-position: calc(100% - 12px) 12px;
  background-repeat: no-repeat;
}
