.overlay {
  /* display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

}

.content {
  background-color: #fefefe;
  position: relative;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: auto; /* Could be more or less, depending on screen size */
  max-width: max-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  border-radius: 12px;
  background: var(--White, #FFF);
}

.hidden {
  display: none;
}

.close {
  position: absolute;
  right: -9px;
  top: -10px;
  width: 20px;
  height: 20px;
  opacity: 0.77;
  cursor: pointer;
  transition-duration: 500ms;
  transition-property: all;
  background-color: white;
  border: 1px solid black;
  border-radius: 50%;
}
.close:hover {
  opacity: 1;
  transform: scale(1.1);
}
.close:before, .close:after {
  position: absolute;
  left: 9px;
  top: 2px;
  content: ' ';
  height: 15px;
  width: 2px;
  background-color: #333;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}