.root {
  padding: 10px 28px 10px 10px;
  background-color: transparent;
  outline: none;
  width: calc(100% - 36px) !important;
  display: flex;
  max-width: 100%;
  position: relative;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Grayscale-300, #EAEEF3);
  background: var(--Grayscale-0, #FFF);
  box-shadow: 0px 1.5px 4px 0px rgba(10, 9, 11, 0.04);
  min-height: 17px;

}

.selectOptions {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  top: 100%;
  left: 0;
  border: 1px solid var(--Grayscale-300, #EAEEF3);
  background: var(--Grayscale-0, #FFF);
  box-shadow: 0px 1.5px 4px 0px rgba(10, 9, 11, 0.04);
  z-index: 9;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  &_up {
    bottom:100%;
    top: auto;
  }
}
.off {
  background: var(--Grayscale-0, #ececec);
}

.option {
  display: flex;
  max-width: 100%;
  //min-height: 17px;
  padding: 8px 12px;
  gap: 8px;
  cursor: pointer;
  &:hover {
    background-color: #EAEEF3FF;
  }
  &_selected{
    background-color: #e5ebf3;
  }
}
.val {
  display: flex;
  gap: 8px;
  flex-direction: row;
  width: 100%;
  min-height: 20px;
  flex-wrap: wrap;

}
.opened::after {
  transform: rotate(180deg);
  margin-top: -3px;

}
.val::after {
  content: url("./imgs/darr.svg");
  position: absolute;
  right: 6px;
  top: 8px;
  display: block;
  transition: 1s;
}
.placeholder {
  opacity: 0.2;
}
.hidden {
  display: none;
}

.error {
  outline: 1px solid red;
}
.select2Input {
  border: none;
  outline: none;
  background: white none;
  width: 80%;
}
.selectInput {
  position: absolute;
  width: 80%;
  border: none;
  outline: none;
  background: white none;

}
.selectedItem {
  background-color: #f0f2f5;
  padding: 3px 7px;
  display: flex;
  gap: 5px;
  align-items: center;
}