:global {
  html, body {
    padding: 0;
    margin: 0;
    font-family: Inter-regular, sans-serif;
  }

  img {
    max-width: 100%;
  }

  @font-face {
    font-family: 'Inter-regular';
    src: local('Inter-regular'), url(./../fonts/Inter-Regular.woff) format('woff'), url(./../fonts/Inter-Regular.woff2) format('woff2');
  }
  .f24 {
    color: var(--Grayscale-1000, #263141);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
  }
  .f14 {
    color: var(--text-primary, #263141);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
  }

  .flex {
    display: flex;
  }

  .sqrt_color {
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 50%;
    box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.2);
  }
  .gap8 {
    gap: 8px;
  }
  .img40 {
    width: 40px;
    border-radius: 4px;
  }
  .img60 {
    width: 60px;
    border-radius: 4px;
  }
}