.wrapper {
  width: 100%;
}
.cp {
  cursor: pointer;
}
.table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;

  .checkColumn {
    width: 30px;
  }

  td {
    color: var(--Grayscale-1000, #263141);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    padding: 12px;
    align-items: center;
    gap: 8px;
    //overflow: hidden;
  }

  th {
    //display: flex;
    padding: 12px;
    //align-items: flex-start;
    position: relative;
    gap: 8px;
    color: var(--Grayscale-600, #7E8794);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-align: left;



    .sortIco {
      background-image: url("./../../imgs/sorting-not-active.svg");
    }
  }

  tr {
    //display: flex;
    padding: 0 20px;
    align-items: center;
    //flex: 1 0 0;
    align-self: stretch;
    border-bottom: 1px solid var(--Grayscale-100, #F6F7FA);
    background: var(--Grayscale-0, #FFF);
  }
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: right;
  gap: 18px;
  align-items: center;
  align-self: stretch;
  color: var(--Grayscale-500, #A1ABB9);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  .btnList {
    display: flex;
    align-items: center;
    gap: 8px;
  }


  .btn {
    cursor: pointer;
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    background: var(--Grayscale-0, #FFF);

    &:hover {
      background: var(--Grayscale-0, #fafafa);
    }
  }

  .btnActive {
    border-radius: 6px;
    background: var(--Main-Primary, #0084FF);
    color: var(--Grayscale-0, #FFF);

  }

}