.radio {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  appearance: auto;
  -webkit-appearance: auto;
  border: 1px solid var(--Main-Primary, #0084FF);
  min-width: 20px;
  min-height: 20px;
  padding: 0;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: var(--Grayscale-0, #FFF);
  box-shadow: 0px 1.5px 4px 0px rgba(10, 9, 11, 0.04);
}
