.header {
  display: flex;
  height: 80px;
  padding: 0px 24px 0px 20px;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
  border-bottom: 1px solid var(--Grayscale-100, #F6F7FA);
  background: var(--Grayscale-0, #FFF);

  &_logo {
    width: 210px;
    flex-shrink: 0;
  }

  &_block_btn {
    display: flex;
    align-items: flex-start;
    gap: 20px;
  }
}

.round {
  background-color: #0084ff;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
}

.btn {
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  cursor: pointer;
}

.textInLine {
  word-wrap: unset;
  max-width: 100%;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  color: #263141;
}

.btnLightGreen {
  color: #0CA157;
  border-radius: 30px;
  background: rgba(12, 161, 87, 0.08);
}

.btnLightRed {
  color: #D7190E;
  background: var(--red-light, #FFF2F1);;

}

.btnLightOrange {
  color: #ED6624;
  background: rgba(237, 102, 36, 0.10);

}

.btnLightBlue {
  color: #0076E4;
  background: rgba(0, 118, 228, 0.10);

}

.btnActive {
  box-shadow: 2px 2px 2px 2px rgba(0, 173, 236, 0.5) !important;
}

.btnWhite {
  border-radius: 8px;
  border: 1px solid var(--Stroke-Input, #EAEEF3);
  background: var(--Grayscale-0, #FFF);
  &_active {
    background: var(--Grayscale-0, #ececec);
  }
}

.btnFull {
  flex: 1;
  height: 100%;
  border-radius: 6px;
}

.cp {
  cursor: pointer;
}

.ml5 {
  margin-left: 7px;
}

.flex {
  display: flex;
  justify-content: space-between;
}


.flexCol {
  flex-direction: column;
}

.gap5 {
  gap: 8px;
}

.gap8 {
  gap: 8px;
}

.w100 {
  width: 100% !important;
  min-width: 100% !important;
}

.editPrice {
  max-width: 87px;
  font-size: 12px;
  line-height: 16px;

  &_gray {
    color: #646E7B;
    cursor: pointer;
  }

  &_blue {
    color: #0076E4;
    cursor: pointer;
  }

  img {
    cursor: pointer
  }
}

.body {
  display: flex;

  &_menu {
    display: flex;
    width: 224px;
    height: 788px;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex-shrink: 0;
    border-right: 1px solid var(--Grayscale-100, #F6F7FA);
    background: var(--Grayscale-0, #FFF);

    > a {
      list-style: none;
      cursor: pointer;
      color: var(--Grayscale-800, #474F5A);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      padding: 8px 12px;
      width: calc(100% - 24px);

      &.active {
        border-radius: 8px;
        background: var(--Main-0, #F2F9FF);
        color: var(--Main-Primary, #0084FF);
      }
    }

  }

  &_panel {
    display: flex;
    width: 100%;
    //height: 100%;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 12px;
    //background: var(--Grayscale-0, #FFF);

    align-self: stretch;

    &_heading {
      display: flex;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      padding: 20px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;

      border-bottom: 1px solid var(--Grayscale-100, #F6F7FA);
      background: var(--Grayscale-0, #FFF);

      &_control {
        flex-direction: row;
        display: flex;
        height: 48px;
        gap: 20px;
        align-content: center;
        align-items: center;
      }

    }

    &_body {
      width: 100%;
      gap: 20px;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      //overflow: hidden;
      background: var(--Grayscale-0, #FFF);

    }
  }

  &_content {
    flex: 1;
    background: var(--Grayscale-100, #F6F7FA);
    padding: 24px;

  }

}

.form {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  align-self: stretch;
}

.roundColor {
  box-shadow: 1px 1px 1px 1px rgba(10, 9, 11, 0.1);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  aspect-ratio: 1;
  display: inline-block;
}

.row {
  display: flex;
  padding: 20px;
  align-items: flex-end;
  gap: 20px;
  align-self: stretch;
  border-bottom: 1px solid var(--Grayscale-100, #F6F7FA);
  background: var(--Grayscale-0, #FFF);

  &_compact {
    padding: 8px;
  }
}

.rowHeader {
  color: var(--Grayscale-1000, #263141);
  /* Title/Semibold 20 */
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
}

.formHorizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
}


.cube2 {
  width: calc(100% - 20px);
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}

.cube3 {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
}

.cube4 {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 8px;
}

.cube5 {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 8px;
}

.flexRight {
  justify-content: flex-end;
}

.flexLeft {
  justify-content: flex-start;
}

.grayText {
  color: var(--Grayscale-600, #7E8794);

  /* Title/Semibold 18 */
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
}

.formVertical {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
  flex: 1 0 0;
  color: var(--Grayscale-1000, #263141);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  > div {
    width: auto;
    min-width: auto;
  }

  label {
    /* Body 2/Regular 14 */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
}

.wmincontent {
  width: min-content;
}

.hidden {
  display: none !important;
}

.popup {
  gap: 12px;
  display: flex;
  min-width: 270px;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  border: 1px solid #EDEEF3;
  background: var(--White, #FFF);
  box-shadow: 0px 2px 23.4px 0px rgba(0, 0, 0, 0.12);
  position: fixed;
  left: 50%;
  z-index: 999;
  top: 70px;
}

.shadowIco {
  box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
  border-radius: 50%;
  padding: 1px;
}

.closeButton {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  transform: rotate(45deg);
  transition: 2s;
  cursor: pointer;
}

.closeButton::after {
  display: inline-block;
  content: "";
  border-top: 2px solid #7E8794;
  width: 1.4rem;
  margin: 0.6rem 0 1rem 0;
  transform: translateY(-1rem);

}

.closeButton::before {
  display: inline-block;
  content: "";
  border-top: 2px solid #7E8794;
  width: 1.4rem;
  margin: 0;
  transform: rotate(90deg);

}

.td {
  color: var(--Grayscale-1000, #263141);
  font-feature-settings: "clig" off, "liga" off;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  padding: 12px;
}

.addCard {
  padding: 12px;
  position: relative;
  background-color: transparent;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 200px;
  min-height: 200px;
  max-width: 200px;
  border-radius: 8px;
  border: 1px solid var(--Grayscale-300, #EAEEF3);
  background: var(--Grayscale-0, #FFF);
  box-shadow: 0px 1.5px 4px 0px rgba(10, 9, 11, 0.04);
  cursor: pointer;
  outline: none;

  &:hover {
    background: var(--Grayscale-0, #EAEEF3);
  }
}

.mainCard {
  background-color: #5eb1ff;

  &::before {
    content: "main";
    color: white;
    position: absolute;
    top: 2px;
    right: 10px;
  }
}

.stickyBottom {
  position: sticky;
  bottom: 0px;
}

table {
  td {
    font-weight: normal;
    padding: 2px 7px;
  }
}

.bgLightRed {
  background-color: #fdeded !important;
}

.bgGray {
  background-color: #d9d9d9 !important;
}

.bgGreen {
  background-color: #b6d7a8 !important;
}

.bgBlue {
  background-color: #cfe2f3 !important;
}

.bgWhite {
  background-color: #ffffff !important;
}

.tableShow {
  color: black;
}

.f12 {
  font-size: 12px;
}

.stripTable {
  td {
    padding: 3px 7px;
  }

  tr:nth-child(2n) {
    background-color: #f6f7fa;
  }

  tr:hover {
    background-color: #e6e8ee;
  }

}

.showBlock {
  display: flex;
  gap: 8px;

  div {
    margin: 0;
    padding: 0;
    font-size: 14px;
  }

  div:first-child {

    color: var(--Grayscale-800, #474F5A);
  }

  div:first-child::first-letter {
    text-transform: capitalize;
  }
}

.quad {
  border-left: 1px solid white;
  padding: 3px 5px;
  color: white;

  &_gray {
    background-color: #646E7B;
  }

  &_blue {
    background-color: #0076E4;
  }

  &_green {
    background-color: #539d33;
  }

  &_red {
    background-color: #98212b;
  }

  &_cp {
    cursor: pointer;
  }
}

.boostCard {
  position: relative;
}

.boostInListing {
  position: absolute;
  top: -9px;
  right: -9px;
  display: flex;
  gap: 8px;
  background-color: white;
  border-radius: 8px;
  align-items: center;
}

.pagRight {
  padding-right: 5px;
}

.CompanyBlock {
  li { height: 1.5rem;}
  p {
    height: 2rem;
    margin: 0;
    padding: 0;
  }
}

.subMenu {
  position: absolute;
  display: flex;
  width: 180px;
  padding: 12px;
  align-items: flex-start;
  gap: 12px;
  border-radius: 8px;
  background: var(--White, #FFF);
  box-shadow: 0px 4px 12px 0px rgba(5, 7, 31, 0.08);
  right: 40px;
  top: -20px;

  ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;

    li {
      width: calc(100% - 12px);
      padding: 6px;

      &:hover {
        background-color: #f2f2f2;
      }

      list-style: none;
      display: flex;
      justify-content: left;
      align-items: center;
      gap: 8px;

      a {
        display: flex;
        align-items: flex-start;
        gap: 6px;
        align-self: stretch;
        width: 100%;
        color: var(--text-primary, #263141);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
      ;
      }
    }
  }

}

.f24 {
  color: var(--text-primary, #263141);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
}

.btnNoActive {
  cursor: unset;
}

.loader {
  aspect-ratio: 1/1;
  height: 100%;
  width: 100%;
  max-width: 25px;
  max-height: 25px;

  border-radius: 50%;
  perspective: 800px;

  .inner {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .inner.one {
    left: 0%;
    top: 0%;
    animation: rotate-one 1s linear infinite;
    border-bottom: 3px solid #97c9ff;
  }

  .inner.two {
    right: 0%;
    top: 0%;
    animation: rotate-two 1s linear infinite;
    border-right: 3px solid #97c9ff;
  }

  .inner.three {
    right: 0%;
    bottom: 0%;
    animation: rotate-three 1s linear infinite;
    border-top: 3px solid #97c9ff;
  }

  @keyframes rotate-one {
    0% {
      transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    }
    100% {
      transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    }
  }

  @keyframes rotate-two {
    0% {
      transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
    }
    100% {
      transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    }
  }

  @keyframes rotate-three {
    0% {
      transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
    }
    100% {
      transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    }
  }
}


