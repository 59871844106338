.main {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.form {
  display: flex;
  width: 400px;
  padding: 32px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border-radius: 12px;
  background: var(--Grayscale-0, #FFF);
}

.header {
  color: var(--Grayscale-1000, #263141);
  /* Title/Semibold 24 */

  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
}

.inputBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  label {
    color: var(--Grayscale-1000, #263141);

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
}

:global {
  .w100 {
    width: calc(100% - 32px);
  }
}