.chooseFile {
  background-image: url("./imgs/chooseImg.svg");
  border: transparent;
  display: flex;
  width: 267px;
  height: 196px;
  padding: 0px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  border-radius: 8px;
  cursor: pointer;
}

.file {
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.imgLine {
  display: flex;
  padding: 20px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 20px;
  align-self: stretch;
  flex-wrap: wrap;
  width: 100%;
  userSelect: "none";
}
.control {
  clear: both;
}

.time {
  box-shadow: 1px 1px 1px 1px rgba(236, 0, 0, 0.5) !important;
}
.checked {
  box-shadow: 0px 3px 3px 3px rgba(0, 173, 236, 0.5) !important;
}

.choosedLine {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
}

.choosedImg {
  background-size: cover;
  position: relative;
  display: flex;
  width: 267px;
  height: 196px;
  cursor: grab;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  border-radius: 8px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
  background-repeat: no-repeat;
  background-position: center;
  &:hover {
    .hiddenHover { display: block;}
  }

  img {
    width: 100%;
  }
}
.orderNumber {
    position: absolute;
    top: 0;
    left: 0;
    padding: 3px;
    background-color: white;
    opacity: 0.7;
}
.mainImg {
  border: 1px solid green;
}
button {
  position: absolute;
  bottom: 0;
  right: 0;
}

.dragged {
  boxShadow: "0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)";
  button: {
    opacity: 0
  }
}

.marker {
  cursor: pointer;
  width: max-content;
  padding: 3px 7px;
  background: var(--Main-Primary, #0084FF);
  box-shadow: 0px 1.5px 4px 0px rgba(10, 9, 11, 0.07);
  color: white;
  border-radius: 6px;
  &_green {
    background: var(--Main-Primary, #1ca600);

  }
  &_red {
    background: #f63737;

  }
}
.hiddenHover {
  display: none;
}
.controlPanel {
  display: flex;
  position: absolute;
  border: 0;
  bottom: 0;

}
